import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

import Header from "src/components/headers/header";
import Ariane from "src/components/headers/ariane";
import Footer from "src/components/footers/footer";

// import Engagement from "../../components/shortcodes/Engagement";
// import Cta from "../../components/shortcodes/Cta";
// import Presse from "../../components/shortcodes/Presse";
// import Prix from "../../components/shortcodes/Prix";
// import CtaCommande from "../../components/shortcodes/CtaCommande";
//
// const shortcodes = { Engagement, Cta, Presse, Prix, CtaCommande };

const mon_fil = [
  "Boutique",
  "/boutique/",
  "Accessoires d'emballage",
  "/boutique/accessoires-emballage/",
];

const MdxPage = () => {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <title>
          Fournitures de demenagement et articles d'emballage | art Moval Lyon
        </title>
        <meta
          name="description"
          content="Des emballages et protections avec le papier bulle | Idéal pour les objets fragiles | Kits déménagement et accessoires pour emballer et transporter vos effets personnels"
        />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white shadow-xl">
        <Header />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <Ariane fil_ariane={mon_fil} />
          <div class="flex flex-1 overflow-hidden pr-4">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-gray-50 overflow-y-auto paragraph">
                <div className="relative bg-gray-50 overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-4">
                    <div className="text-lg mx-auto">
                      <h1>
                        <span className="mt-8 mb-4 block text-5xl text-left leading-8 font-bold tracking-tight text-art-marron-fonce sm:text-4xl lg:text-6xl">
                          Accessoires d'emballage
                        </span>
                      </h1>
                      {/* <p className="mt-8 mb-8 text-xl text-art-marron-fonce leading-8">
                            {excerpt}
                          </p> */}
                      {/* <div className="mt-8 text-xl flex-none text-art-orange">
                        Nos catégories
                      </div> */}
                      <ul
                        className="lg:flex mt-6 lg:space-x-3 text-xl leading-6 text-gray-600"
                      >
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/cartons/">Cartons</a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/accessoires-emballage/">
                            Accessoires d'emballage
                          </a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/manutention-transport/">
                            Manutention et transport
                          </a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/kit/">Kits</a>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="mx-auto max-w-2xl pb-16 pt-10 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pb-24 lg:pt-16">
                      <div className="col-span-1">
                        <div className="rounded-3xl shadow-lg bg-gray-900/5 p-4 ring-1 ring-inset ring-gray-900/10 lg:rounded-3xl"></div>
                      </div>
                      <div className="col-span-2 prose-h2:text-4xl prose-h3:text-3xl mt-6 max-w-none prose-a:text-art-bleu prose-headings:text-art-marron-fonce prose prose-blue prose-lg text-art-marron-fonce mx-auto">
                        test
                      </div>
                    </div> */}

                    <div className="text-lg mx-auto">
                      <div className="mx-auto max-w-2xl py-4 lg:max-w-7xl">
                        {/* <h2 className="text-2xl font-bold tracking-tight text-art-marron-fonce">
                          Voir nos kits
                        </h2> */}

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/etiquettes-adhesives/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Étiquettes adhésives"
                                    src="../../markdown-pages/etiquettes-adhesives.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Étiquettes adhésives
                                  </h3>
                                  {/* <p className="mt-1 text-sm text-gray-500">
                                    {product.color}
                                  </p> */}
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  21€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/ficelle-bandelette/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Ficelle bandelette"
                                    src="../../markdown-pages/ficelle-bandelette.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Ficelle bandelette
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  12,80€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/film-etirable/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Film étirable"
                                    src="../../markdown-pages/film-etirable.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Film étirable
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  16,40€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/housse-de-matelas/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Housse de matelas"
                                    src="../../markdown-pages/housse-de-matelas.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Housse de matelas
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  4,80€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/marqueur-permanent/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Marqueur permanent"
                                    src="../../markdown-pages/marqueur-permanent.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Marqueur permanent
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  2,30€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/maxi-cutter/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Maxi cutter"
                                    src="../../markdown-pages/maxi-cutter.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Maxi cutter
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  3,00€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/papier-blanc-journal/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Papier blanc journal"
                                    src="../../markdown-pages/papier-blanc-journal.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Papier blanc journal
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  34,60€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/pistolet-devidoir-adhesif/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Pistolet dévidoir pour adhésif"
                                    src="../../markdown-pages/pistolet-devidoir-adhesif.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Pistolet dévidoir pour adhésif
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  10,80€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/bulle-polyethylene/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Bulle polyéthylène"
                                    src="../../markdown-pages/bulle-polyethylene.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Bulle polyéthylène
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  12,00€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/adhesif-pp-solvant/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Adhésif PP solvant"
                                    src="../../markdown-pages/adhesif-pp-solvant.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Adhésif PP solvant
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  4,00€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/sac-polyethylene/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Sac polyéthylène"
                                    src="../../markdown-pages/sac-polyethylene.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Sac polyéthylène
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  1,10€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/accessoires-emballage/couverture-naprotect/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Couverture naprotect"
                                    src="../../markdown-pages/couverture-naprotect.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Couverture naprotect
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  12,00 € TTC
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MdxPage;
